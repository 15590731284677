import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import Link from '@components/Link';
import HeadingsContainer from '@components/Headings/Headings.Container';
import Grid from '@components/Grid';
import CardDefault from '@components/Card/Card.Default';
import CardImageContainer from '@components/Card/Card.Image';
import CardBorder from '@components/Card/Card.Border';
import CardContent from '@components/Card/Card.Content';

import Image from '../../components/Image';

import SectionTemplate from '@types';

const LandingServices: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();

    return (
        <Transitions.CSS.FadeIn as={Section} id="services" {...sectionContext}>
            {/*<Section >*/}
                <Wrapper>
                    <HeadingsContainer center>
                        <Headings.h2>{intl.formatMessage({ id: 'services-title' })}</Headings.h2>
                        <Paragraph center lead>{intl.formatMessage({ id: 'services-subtitle' })}</Paragraph>
                    </HeadingsContainer>

                    <StyledGrid columns="4">
                        <Link to="/web-development-services/">
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="macbook-keyboard.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'services-web' })}</CardHeader>
                                    {/*<CardParagraph>{intl.formatMessage({ id: 'services-web-excerpt' })}</CardParagraph>*/}
                                </StyledCardContent>
                            </CardDefault>
                        </Link>

                        <Link to="/seo-services/">
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="mac-on-desk.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'services-seo' })}</CardHeader>
                                    {/*<CardParagraph>{intl.formatMessage({ id: 'services-seo-excerpt' })}</CardParagraph>*/}
                                </StyledCardContent>
                            </CardDefault>
                        </Link>

                        <Link to="/online-marketing/">
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="girl-writing-in-notebook.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'services-marketing' })}</CardHeader>
                                    {/*<CardParagraph>{intl.formatMessage({ id: 'services-marketing-excerpt' })}</CardParagraph>*/}
                                </StyledCardContent>
                            </CardDefault>
                        </Link>

                        <Link to="/app-development/">
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="macbook-programming.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'services-app' })}</CardHeader>
                                    {/*<CardParagraph>{intl.formatMessage({ id: 'services-app-excerpt' })}</CardParagraph>*/}
                                </StyledCardContent>
                            </CardDefault>
                        </Link>
                    </StyledGrid>
                </Wrapper>
            {/*</Section>*/}
        </Transitions.CSS.FadeIn>
    );
};


const CardHeader = styled(Headings.h4)`
    margin-bottom: 0px;
    // text-align: center;
    font-weight: bold;
`;

// const CardParagraph = styled(Paragraph)`
//     margin-bottom: 0px;
//     font-size: 16px;
// `;

const StyledGrid = styled(Grid)`
    margin-top: 50px;
`;

const StyledCardContent = styled(CardContent)`
    padding: 7% 5%;
`;

export default LandingServices;