import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import GridCustom from '@components/Grid/Grid.Custom';
import HeroWrapper from '@components/Hero/Hero.Wrapper';
import LinkContainer from '@components/Link/Link.Container';
import FormattedMessage from '@components/FormattedMessage';

// import Wave from '@components/Wave';
import Image from '../../components/Image';

import SectionTemplate from '@types';

const LandingIntroduction: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();
    return (
        <Section {...sectionContext}>
            {/*<Wave top waveContext={{ gradientBg: true }} />*/}

            <Transitions.CSS.FadeIn>
                <Wrapper zIndex="1">
                    <StyledGridCustom columns="55fr 45fr" rows="auto" columnGap="30px" rowGap="40px">
                        <TextContainer>
                            <Headings.h2><FormattedMessage message="intro-title" /></Headings.h2>
                            { intl.formatMessage({ id: 'intro-description' }) !== 'intro-description' ?
                            <Paragraph lead>{intl.formatMessage({ id: 'intro-description' })}</Paragraph> : ''
                            }
                        </TextContainer>
                        <Transitions.CSS.FadeInZoom as={FloatingImages} delay="0.5">
                            <FloatingImagesContainer>
                                <FloatingImage>
                                    <Image imgName="ipad-colorful-background.jpg" />
                                </FloatingImage>
                                <FloatingImage>
                                    <Image imgName="girl-writing-in-notebook.jpg" />
                                </FloatingImage>
                        </FloatingImagesContainer>
                        </Transitions.CSS.FadeInZoom>
                    </StyledGridCustom>
                </Wrapper>
            </Transitions.CSS.FadeIn>
        </Section>
    );
};

const StyledGridCustom = styled(GridCustom)`
    ${mediaqueries.tablet_ipad`
        grid-template-columns: 1fr;

        & p {
            font-size: 18px;
        }
    `};
`;

const Heading = styled(Headings.h1)`
    font-weight: 800;
`;

const TextContainer = styled.div`
    max-width: 550px;
    text-align: left;

    ${mediaqueries.from_tablet_ipad`
        max-width: unset;
        text-align: left;
    `};
`;

const FloatingImages = styled.div`
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const FloatingImagesContainer = styled.ul`
    height: auto;
    min-height: 250px;
    
    ${mediaqueries.from_mobile_i6`
        min-height: 350px;
    `};
    
    ${mediaqueries.from_tablet_ipad`
        height: auto;
        min-height: unset;
    `};

`;

const FloatingImage = styled.li`
    position: absolute;
    width: 200px;
    height: auto;
    top: 0;
    left: 0;
    margin: 0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .08); // lighter box-shadow
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.14), 0 15px 30px rgba(0, 0, 0, 0.14); // stronger box-shadow
    border-radius: 0.25em;
    will-change: transform;

    // Was in .floating-image
    // animation: none;
    // transition: .3s;
    // filter: blur(0px);


    &:nth-of-type(1) {
        top: 0;
        bottom: auto;
        left: 50%;
        right: auto;
        transform: translateY(0%) translateX(-60%) translateZ(0);
    }

    &:nth-of-type(2) {
        top: 0;
        bottom: auto;
        left: 50%;
        right: auto;
        transform: translateY(30%) translateX(-40%) translateZ(0);
    }


    ${mediaqueries.from_mobile_i6`
        width: 250px;
    `};

    ${mediaqueries.from_tablet_ipad`
        &:nth-of-type(1) {
            left: 0;
            transform: translateY(10%) translateX(25%);
        }

        &:nth-of-type(2) {
            left: 0;
            transform: translateY(-15%) translateX(5%) translateZ(0);
        }
    `};

    ${mediaqueries.from_laptop`
        width: 300px;

        &:nth-of-type(1) {
            right: 0;
            left: auto;
            transform: translateY(5%) translateX(0%) translateZ(0);
        }

        &:nth-of-type(2) {
            right: auto;
            transform: translateY(-15%) translateX(20%) translateZ(0);
        }
    `};
`;

export default LandingIntroduction;