import React from 'react';
import styled from '@emotion/styled';

import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

/*
 * This component is built using `gatsby-background-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 */

const StyledGatsbyBackgroundImage = styled(BackgroundImage)`
  &:after {
    filter: blur(8px);
  }
`

const Background: React.FC<{ src: string }> = ({ src, children, ...props }) => {

  return (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    `}
    render={ data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === src
      )
      
      if (!image) {
        return null
      }

      return <StyledGatsbyBackgroundImage
              Tag={ image.node.originalName }
              fluid={ image.node.fluid }
              { ...props }
              >{ children }</StyledGatsbyBackgroundImage>
    }}
  />
  );
}

export default Background;
