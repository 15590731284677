import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import HeadingsContainer from '@components/Headings/Headings.Container';
import Grid from '@components/Grid';
import CardDefault from '@components/Card/Card.Default';
import CardImageContainer from '@components/Card/Card.Image';
import CardContent from '@components/Card/Card.Content';
import Link from '@components/Link';
import LinkContainer from '@components/Link/Link.Container';
// import HeroBackground from '@components/Hero/Hero.Background';

import SectionTemplate from '@types';

import { useThemeUI } from 'theme-ui';

import BusinessAnalytics from '@icons/undraw/BusinessAnalytics.Icon';
import Content from '@icons/undraw/Content.Icon';
import Marketing from '@icons/undraw/Marketing.Icon';


const LandingStrategy: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();

    const context = useThemeUI();
    const { theme } = context;
    const fillColor = theme.colors.gradientBg;

    return (
        <Section { ...sectionContext }>
            {/*{ sectionContext && sectionContext.gradientBg && <HeroBackground /> }*/}

            <Wrapper>
                <HeadingsContainer center>
                    <Headings.h2>{intl.formatMessage({ id: 'strategy-title' })}</Headings.h2>
                    <Paragraph center lead>{intl.formatMessage({ id: 'strategy-subtitle' })}</Paragraph>
                </HeadingsContainer>

                <StyledGrid columns="3">
                    <ModifiedCardDefault /*hover animateImage animateBorder*/ flexStartAlign>
                        <StyledCardImageContainer flexible>
                            <BusinessAnalytics fill={fillColor} />
                        </StyledCardImageContainer>
                        <CardContent>
                            <CardHeader>{intl.formatMessage({ id: 'strategy-think' })}</CardHeader>
                            <CardParagraph>{intl.formatMessage({ id: 'strategy-think-excerpt' })}</CardParagraph>
                        </CardContent>
                    </ModifiedCardDefault>

                    <ModifiedCardDefault /*hover animateImage animateBorder*/ flexStartAlign>
                        <StyledCardImageContainer flexible>
                            <Content fill={fillColor} />
                        </StyledCardImageContainer>
                        <CardContent>
                            <CardHeader>{intl.formatMessage({ id: 'strategy-create' })}</CardHeader>
                            <CardParagraph>{intl.formatMessage({ id: 'strategy-create-excerpt' })}</CardParagraph>
                        </CardContent>
                    </ModifiedCardDefault>

                    <ModifiedCardDefault /*hover animateImage animateBorder*/ flexStartAlign>
                        <StyledCardImageContainer flexible>
                            <Marketing fill={fillColor} />
                        </StyledCardImageContainer>
                        <CardContent>
                            <CardHeader>{intl.formatMessage({ id: 'strategy-manage' })}</CardHeader>
                            <CardParagraph>{intl.formatMessage({ id: 'strategy-manage-excerpt' })}</CardParagraph>
                        </CardContent>
                    </ModifiedCardDefault>
                </StyledGrid>

                {/* Cleanup */}
                <StyledHeadingsContainer center>
                    <Paragraph lead center className="opacity-90">{intl.formatMessage({ id: 'strategy-cta' })}</Paragraph>
                    <StyledLinkContainer>
                        {sectionContext && sectionContext.gradientBg ? 
                        <Link to="/contact/" className="button button--dark">{intl.formatMessage({ id: 'strategy-cta-request' })}</Link>
                         : 
                        <Link to="/contact/" className="button button--dark">{intl.formatMessage({ id: 'strategy-cta-request' })}</Link>
                        }
                        <Link data-scroll to="/#services" className="button button--white">{intl.formatMessage({ id: 'strategy-cta-learn' })}</Link>
                    </StyledLinkContainer>
                </StyledHeadingsContainer>
                {/* Cleanup End */}
            </Wrapper>
        </Section>
    );
};

const CardHeader = styled(Headings.h3)`
    // margin-bottom: 0px;
    // text-align: center;
`;

const CardParagraph = styled(Paragraph)`
    margin-bottom: 0px;
    font-size: 16px;
`;

const StyledCardImageContainer = styled(CardImageContainer)`
    // Center the SVG in the top grid layout
    display: flex;
    align-items: center;
    justify-content: left;
`;

const StyledGrid = styled(Grid)`
    margin-top: 50px;

    ${mediaqueries.tablet_ipad`
        grid-template-columns: 1fr;
        max-width: 550px;
    `};  

    ${mediaqueries.laptop`
        grid-template-columns: 1fr;
        max-width: 620px;
    `};   
`;

const ModifiedCardDefault = styled(CardDefault)`
    align-items: center;
    box-shadow: none;
    background: transparent;

    ${mediaqueries.laptop`
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-column-gap: 2%;
    `};

    ${mediaqueries.tablet`
        grid-template-columns: 1fr;
    `};
`;

const StyledHeadingsContainer = styled(HeadingsContainer)`
    ${mediaqueries.tablet_ipad`
        margin-top: 50px;

    `};
    
    & a:first-of-type {
        margin-bottom: 0px;
    }
`;

const StyledLinkContainer = styled(LinkContainer)`
    ${mediaqueries.mobile_i6p`
        width: 80%;
    `}
    ${mediaqueries.mobile_i6p`
        & a:first-of-type {
            margin-right: 0px;
            margin-bottom: 20px;
        }
    `}
`;

export default LandingStrategy;
