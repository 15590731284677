import React from "react";

import { Icon } from '@types';

const MarketingIcon: Icon = ({ fill = '#c4c4c4', title = 'marketing', ...props }) => (
<svg { ...props } id="adb96194-0c93-4caf-add0-a6cc39fafbf3" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="965.24548" height="817.22311" viewBox="0 0 965.24548 817.22311">
    <title>{ title }</title>
    <path d="M634.67072,797.32639C392.27189,778.87982,103.2,591.06011,117.91708,397.669S412.60339,24.24691,655.00223,42.69348,1036.93411,422.55541,1022.217,615.94653,877.06956,815.773,634.67072,797.32639Z" transform="translate(-117.37726 -41.38845)" fill="#f2f2f2" />
    <rect x="1.24548" y="71.00773" width="460" height="476" fill="#3f3d56" />
    <path d="M350.90069,254.425c-.75738-.01764-1.51635-.02887-2.27795-.02887a95.95035,95.95035,0,1,0,84.44317,50.29724l-79.44317,59.70276Z" transform="translate(-117.37726 -41.38845)" fill="none" />
    <path d="M433.06591,304.69341a96.01259,96.01259,0,1,1-84.44317-50.29724c.7616,0,1.52057.01123,2.27795.02887l-1.01519-41.01288c-.42133-.00378-.84058-.016-1.26276-.016a136.96052,136.96052,0,1,0,117.471,66.47632Z" transform="translate(-117.37726 -41.38845)" fill={ fill } />
    <path d="M433.06591,304.69341l33.02784-24.82092A136.91923,136.91923,0,0,0,349.8855,213.41216l1.01519,41.01288A95.98585,95.98585,0,0,1,433.06591,304.69341Z" transform="translate(-117.37726 -41.38845)" fill={ fill } opacity="0.3" />
    <line x1="300.13583" y1="427.44435" x2="348.24548" y2="476.00773" fill="none" stroke="#2f2e41" strokeMiterlimit="10" strokeWidth="2" />
    <line x1="332.90931" y1="129.91461" x2="299.472" y2="189.53747" fill="none" stroke="#2f2e41" strokeMiterlimit="10" strokeWidth="2" />
    <rect x="309.24548" y="476.00773" width="79" height="18" fill="#f2f2f2" />
    <rect x="291.24548" y="112.00773" width="79" height="18" fill="#f2f2f2" />
    <rect x="505.24548" y="67.00773" width="460" height="192" fill="#3f3d56" />
    <path d="M808.45346,256.39617h-52a181.87191,181.87191,0,0,1,0-62h52A111.55883,111.55883,0,0,0,808.45346,256.39617Z" transform="translate(-117.37726 -41.38845)" fill="#ff6584" />
    <path d="M953.78418,256.39617h-52a181.87191,181.87191,0,0,1,0-62h52A111.55883,111.55883,0,0,0,953.78418,256.39617Z" transform="translate(-117.37726 -41.38845)" fill="#ff6584" />
    <path d="M734.62274,214.39617h-52a181.87191,181.87191,0,0,1,0-62h52A111.55883,111.55883,0,0,0,734.62274,214.39617Z" transform="translate(-117.37726 -41.38845)" fill={ fill } />
    <path d="M827.62274,214.39617h52a181.87242,181.87242,0,0,0,0-62h-52A111.559,111.559,0,0,1,827.62274,214.39617Z" transform="translate(-117.37726 -41.38845)" fill={ fill } />
    <path d="M970.62274,214.39617h52a181.87242,181.87242,0,0,0,0-62h-52A111.559,111.559,0,0,1,970.62274,214.39617Z" transform="translate(-117.37726 -41.38845)" fill={ fill } />
    <path d="M994.62274,799.39617c0,19.94173-93.17706,59.21538-207.5,59.21538s-206.5-39.27365-206.5-59.21538,92.17706-13,206.5-13S994.62274,779.45444,994.62274,799.39617Z" transform="translate(-117.37726 -41.38845)" fill="#3f3d56" />
    <path d="M994.62274,799.39617c0,19.94173-93.17706,59.21538-207.5,59.21538s-206.5-39.27365-206.5-59.21538,92.17706-13,206.5-13S994.62274,779.45444,994.62274,799.39617Z" transform="translate(-117.37726 -41.38845)" opacity="0.1" />
    <ellipse cx="669.74548" cy="758.11542" rx="207" ry="36.10769" fill="#3f3d56" />
    <ellipse cx="669.74548" cy="758.11542" rx="162" ry="28.25819" opacity="0.1" />
    <path d="M715.62274,585.39617h-52a181.87191,181.87191,0,0,1,0-62h52A111.55883,111.55883,0,0,0,715.62274,585.39617Z" transform="translate(-117.37726 -41.38845)" fill={ fill } />
    <path d="M880.13933,271.44367l7.15082,3.57541s38.73363,97.132,25.62379,120.37221-54.22708,66.741-54.22708,66.741l-7.74673-22.04837,31.68261-68.68777Z" transform="translate(-117.37726 -41.38845)" fill="#575a89" />
    <path d="M880.13933,271.44367l7.15082,3.57541s38.73363,97.132,25.62379,120.37221-54.22708,66.741-54.22708,66.741l-7.74673-22.04837,31.68261-68.68777Z" transform="translate(-117.37726 -41.38845)" opacity="0.1" />
    <path d="M872.3926,757.10381s10.13034,23.24018,4.76722,25.02788-11.918,1.78771-18.473,1.78771-14.30165-2.38361-15.49345-4.76722,4.76721-17.87706,4.76721-17.87706Z" transform="translate(-117.37726 -41.38845)" fill="#f2f2f2" />
    <path d="M735.931,769.02185s6.55493,20.85657,3.57542,22.04837-12.51395,6.55493-17.87707,4.76722-13.70574-5.959-15.49345-7.74673,0-11.32213,0-11.32213l8.34263-11.918Z" transform="translate(-117.37726 -41.38845)" fill="#f2f2f2" />
    <path d="M853.32374,459.1528l8.542,2.97951s2.18423,20.26067-.19938,27.4115-7.74673,58.9943-4.76722,69.72053,1.19181,28.0074,1.19181,28.0074,17.87706,58.39839,14.30164,70.91234-2.97951,30.391.59591,39.32953,6.55492,12.51394,4.76721,19.66477-5.36312,10.72623-1.7877,16.68525,10.72623,7.74673,7.15082,13.10985-7.15082,9.53443-5.959,13.10984-31.58281,10.13033-34.56232,4.76722c0,0,9.53444-8.34263,0-13.10985s-6.55492-22.64427-6.55492-22.64427-16.68526-68.52874-16.08935-73.89185-26.21969-88.7894-26.21969-88.7894S754.404,685.59557,752.0204,690.95869s2.97951,18.473,0,22.04837,1.1918,12.51394-2.97951,16.08936,7.15082,16.68525,0,21.45247-10.72624,19.66476-10.13034,22.64427-29.7951-.5959-29.7951-4.76721.5959-7.74673-2.38361-14.89755-4.76721-11.918-2.3836-14.30165,4.17131-5.36312,1.7877-13.70575,3.57541-33.96641,1.78771-38.73363-1.19181-36.94592-1.19181-36.94592,5.959-47.07626,4.17132-54.823,0-9.53443.5959-12.51394,4.17131-35.15822,6.55492-39.92543,5.36312-86.4058,19.06887-90.57711S853.32374,459.1528,853.32374,459.1528Z" transform="translate(-117.37726 -41.38845)" fill="#2f2e41" />
    <path d="M858.091,778.55628s21.45247-3.57541,22.64427,0,4.76722,31.58281-3.57541,32.17871-22.64428,2.38361-25.02788-2.97951-13.70575-8.93853-13.70575-8.93853-16.68526-4.76722-16.68526-10.13033.5959-13.70575,3.57541-14.30165,14.89756-7.15083,14.89756-9.53443S858.091,778.55628,858.091,778.55628Z" transform="translate(-117.37726 -41.38845)" fill="#2f2e41" />
    <path d="M724.013,788.09071s15.49346-4.76721,19.06887-1.7877,8.34263,35.75412,0,35.15822-30.391,2.97951-31.58281-1.78771-17.28116-5.959-17.28116-5.959-17.28116-8.34263-16.08935-17.28116,2.97951-11.918,10.13033-12.51394,12.51394-8.93853,13.10985-9.53443,5.84986-4.95179,6.50034-.09229S716.26628,789.87842,724.013,788.09071Z" transform="translate(-117.37726 -41.38845)" fill="#2f2e41" />
    <circle cx="655.49971" cy="132.9232" r="36.35002" fill="#ffb9b9" />
    <path d="M804.45977,182.65427s2.38361,39.92544,11.32214,43.50085-45.28855,3.57541-45.28855,3.57541-.5959-25.02788-8.34263-30.9869S804.45977,182.65427,804.45977,182.65427Z" transform="translate(-117.37726 -41.38845)" fill="#ffb9b9" />
    <path d="M818.76142,217.21659s-36.94592-13.70575-50.05577,7.74672c0,0-41.71314,19.66477-44.69265,31.58281s25.02789,101.30334,25.02789,101.30334,0,73.89185-5.959,81.63858-17.28116,16.08935-9.53443,17.87706,129.31073,14.89755,128.71483,7.15082-8.34263-14.30165,0-19.06886,1.1918-10.13034,6.55492-25.62379,18.473-143.61238,18.473-143.61238-8.93853-16.08936-10.72623-17.87706S818.76142,217.21659,818.76142,217.21659Z" transform="translate(-117.37726 -41.38845)" fill="#575a89" />
    <path d="M712.095,478.22167s-23.24018,52.43938-2.97951,59.5902S728.18432,482.393,728.18432,482.393Z" transform="translate(-117.37726 -41.38845)" fill="#ffb9b9" />
    <path d="M790.313,181.072c-2.10707-4.2219-5.26118-9.09241-9.97265-8.83532-2.38237.13-4.43681,1.61235-6.56012,2.70051s-4.84382,1.76273-6.7853.37593c-2.83979-2.02847-1.9593-6.83973-4.49941-9.23284-2.773-2.61248-8.35017-1.15389-10.13937-4.51738-.95535-1.796-.18381-4.02427-.60042-6.01539-2.90247.05448-5.89782.09268-8.58818-.99783s-5.02-3.61721-4.94477-6.51921a7.45613,7.45613,0,0,1,2.66258-5.12657c3.66321-3.33087,8.96206-3.96962,13.48235-5.98972,3.59725-1.60761,6.72513-4.113,10.26075-5.852,6.08507-2.9929,13.10536-3.57329,19.8669-3.05659,10.25224.78344,20.62,4.1858,28.05445,11.28873s11.369,18.3087,8.16274,28.07814c-1.81684,5.53584-5.66716,10.18892-7.81583,15.6046a54.62165,54.62165,0,0,0-2.72325,11.44581l-1.50852,9.25547c.23477-1.4404-2.928-5.05334-3.96526-5.913-2.26247-1.87519-5.55344-1.23893-7.65391-2.97585C793.9389,192.22034,792.141,184.7348,790.313,181.072Z" transform="translate(-117.37726 -41.38845)" fill="#2f2e41" />
    <path d="M737.71875,252.37481l-9.53443,5.959s-15.49346,93.55661-13.70575,106.66646-7.15082,87.00169-4.17131,92.96071,4.17131,7.15082,4.17131,10.72624-8.34263,16.68525-2.97951,19.06886,25.02789,6.55492,25.02789,0-3.57542-15.49345,2.97951-19.66477,11.32213-30.9869,11.32213-30.9869,2.97951-27.41149,5.959-32.77461,7.15083-25.02789,7.15083-33.37051,7.15082-42.30905,2.97951-59.5902S761.55483,247.60759,737.71875,252.37481Z" transform="translate(-117.37726 -41.38845)" opacity="0.1" />
    <path d="M733.54744,250.5871l-9.53444,5.959s-15.49345,93.55662-13.70574,106.66646-7.15083,87.00169-4.17132,92.96071,4.17132,7.15083,4.17132,10.72624-8.34263,16.68526-2.97951,19.06886,25.02788,6.55493,25.02788,0-3.57541-15.49345,2.97951-19.66476,11.32214-30.98691,11.32214-30.98691,2.97951-27.41149,5.959-32.77461,7.15082-25.02788,7.15082-33.37051,7.15083-42.309,2.97951-59.5902S757.38352,245.81988,733.54744,250.5871Z" transform="translate(-117.37726 -41.38845)" fill="#575a89" />
</svg>
);

export default MarketingIcon;
