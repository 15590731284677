import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import HeroWrapper from '@components/Hero/Hero.Wrapper';
import LinkContainer from '@components/Link/Link.Container';

// import Image from '../../components/Image';
import Background from '@components/BackgroundImage';
import SVGBackground from '@misc/hero-background.svg';

import SectionTemplate from '@types';

const LandingHero: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();
    
    return (
        <Transitions.CSS.FadeIn>
            <Section hero gradientBg {...sectionContext}>
                <BackgroundImage src="laptop-gradient.jpg" />
                {/*<ColoredBackground />*/}
                <CenterDiv>
                <HeroBackground />
                </CenterDiv>

                <Wrapper zIndex="1">
                    <HeroWrapper narrow center>
                        <Transitions.CSS.FadeInUp delay="0.1">
                            <Heading>DLMR Consulting</Heading>
                        </Transitions.CSS.FadeInUp>
                        <Transitions.CSS.FadeInUp delay="0.2">
                            <Paragraph subtitle>{intl.formatMessage({ id: 'landing-description' })}</Paragraph>
                        </Transitions.CSS.FadeInUp>
                        <Transitions.CSS.FadeInUp delay="0.3">
                            <LinkContainer>
                                <Link to="/#services" className="button button--dark" data-scroll>{intl.formatMessage({ id: 'landing-discover' })}</Link>
                                <Link to="/contact/" className="button button--white">{intl.formatMessage({ id: 'landing-get-in-touch' })}</Link>
                            </LinkContainer>
                        </Transitions.CSS.FadeInUp>
                    </HeroWrapper>
                </Wrapper>
            </Section>
        </Transitions.CSS.FadeIn>
    );
};

const CenteredWrapper = styled(Wrapper)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;

const ColoredBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: #369BF0;
    // background: #008bd0;
    // background: #000000;
    opacity: 0.8;
    // opacity: 0.5;
    z-index: 1;
`;

const Heading = styled(Headings.h1)`
    font-weight: 800;
`;

const BackgroundImage = styled(Background)`
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0px;
    left: 0px;
    right: unset;
    bottom: unset;
    z-index: 0;
    // background-color: rgba(0,0,0,0.2);
`;

const HeroBackground = styled(SVGBackground)`
    position: absolute;
    // width: 100%;
    // height: 100%;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    // transform: matrix(-1, 0, 0, 1, 0, 0);
    min-width: 700px !important;
`;

const CenterDiv = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export default LandingHero;