import React from 'react';
import { FormattedMessage as Message } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';

import Anchor from '@components/Anchor';

/**
 * Utility Component for printing text in a formatted way
 * @example
 *   <FormattedMessage message='message-id' />;
*/
const FormattedMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <Message id={message} values={{
            dot: <BlueSpan>.</BlueSpan>,
            technology: <BlueSpan><Message id="intro-title-technology" /></BlueSpan>,
            effect: <BlueSpan><Message id="intro-title-effect" /></BlueSpan>,

            appTechnology: <BlueSpan><Message id="app-introduction-title-technology" /></BlueSpan>,
            appResult: <BlueSpan><Message id="app-introduction-title-result" /></BlueSpan>,
            appEffect: <BlueSpan><Message id="app-introduction-title-effect" /></BlueSpan>,
            appProfessional: <BlueSpan><Message id="app-introduction-title-professional" /></BlueSpan>,
            
            googlePWALink: (
                <Anchor href={`https://developers.google.com/web/showcase/2016/aliexpress`} target={`_blank`} rel={`noopener noreferrer`}>
                    <Message id="web-services-3-link" />
                </Anchor>
            ),
            appGooglePWALink: (
                <Anchor href={`https://developers.google.com/web/showcase/2016/aliexpress`} target={`_blank`} rel={`noopener noreferrer`}>
                    <Message id="app-services-2-link" />
                </Anchor>
            ),
        }} />   
    );
};

const BlueSpan = styled.span`
    color: ${p => p.theme.colors.brand};
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    display: inline;    
`;

export default FormattedMessage;
