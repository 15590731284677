import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';

import Layout from '@components/Layout';
import LandingHero from '@sections/landing/Landing.Hero.BackgroundImage';
import LandingIntroduction from '@sections/landing/Landing.Introduction';
import LandingServices from '@sections/landing/Landing.Services';
import LandingTechnologies from '@sections/landing/Landing.Technologies';
import LandingStrategy from '@sections/landing/Landing.Strategy';
import LandingCTA from '@sections/landing/Landing.CTA';

import '../components/Utils';


const IndexPage: React.FC = () => {
    const intl = useIntl();
    const invertHeader = true;
    
    return (
    <Layout id="index" invertHeader={invertHeader}>
        <SEO
            /*lang={intl.locale}*/
            lang={intl.formatMessage({ id: 'current' })}
            title={intl.formatMessage({ id: 'home-meta-title' })}
            description={intl.formatMessage({ id: 'home-meta-description'})}
            slug='/'
        />
        <LandingHero />
        <LandingIntroduction />
        <LandingServices />
        <LandingTechnologies sectionContext={{small: true, noPaddingTop: true, noPaddingBottom: true, }} />
        <LandingStrategy />
        <LandingCTA sectionContext={{ small: true, noPaddingTop: true, }}/>
    </Layout>
    );
};

export default IndexPage;
